body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
  }
  
  .letter-container {
    margin: 10px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: block;
    text-align: left;
  }
  
  h1, h2, h3 {
    color: #333;
    margin-top: 0;
  }
  
  ul, ol {
    /* list-style-type: disc; */
    margin-left: 20px;
    padding-left: 0;
  }
  
  p {
    line-height: 1.6;
    margin: 10px 0;
  }
  
  @media (max-width: 600px) {
    .letter-container {
      margin: 5px;
      padding: 10px;
    }
  
    h1 {
      font-size: 1.5em;
    }
  
    h2 {
      font-size: 1.2em;
    }
  
    h3 {
      font-size: 1em;
    }
  
    p, li {
      font-size: 1.1em;
    }
  }